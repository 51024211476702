@import url("https://fonts.googleapis.com/css?family=Roboto+Condensed&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
  color: black;
  font-family: "Roboto Condensed", sans-serif;
  margin: 0;
}

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

a{
  margin: 20px;
  font-size: 5vh;
  text-transform: lowercase;
  line-height: 1;
  text-decoration: none;
  color: black;
}

span{
  display: flex;
  align-items: center;
  justify-content: center;
}

img{
  height: 40vh;
}

.icon-container{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (min-width: 768px) {
  .container {
    width: 1100px;
  }
  h1 {
    font-size: 58px;
  }
}
